import cookies from "next-cookies";
import { serialize } from "cookie";
import axios from "axios";
import { logError } from "../config/SentryConfig";
import LoginLayout from "../components/login/LoginLayout";
import { Fragment } from "react";

const baseUrl = process.env.FSM_REST_ENDPOINT;

const Login = ({ fsmRedirect }) => {
	return <LoginLayout fsmRedirect={fsmRedirect} />;
};

const getUserContext = accessToken => {
	return axios
		.get(`${baseUrl}/profile/user`, {
			headers: {
				access_token: "bearer " + accessToken
			}
		})
		.then(resp => {
			return resp.data.data;
		})
		.catch(err => {
			logError(err, 0);
			return false;
		});
};

export async function getServerSideProps(appContext) {
	const { res } = appContext;
	const { token, fsmRedirect = "/" } = cookies(appContext);
	appContext.res.setHeader("Set-Cookie", [
		serialize("fsmRedirect", "", { path: "/", maxAge: -1 })
	]);
	if (token) {
		const userData = await getUserContext(token);
		if (userData) {
			res.statusCode = 302;
			res.setHeader("Location", "/");
			res.end();
			return { props: {} };
		}
	}
	return { props: { fsmRedirect } };
}

Login.CustomLayout = ({ children }) => <Fragment>{children}</Fragment>;

export default Login;
