const LoginInputFields = ({
	value,
	label,
	placeholder,
	name,
	type = "text",
	className,
	autoComplete,
	containerClassName,
	inputFieldContainerClassName,
	labelClassName,
	trailingIcon,
	leadingIcon,
	error,
	touched,
	onChange = () => {},
	onError = () => {},
	onBlur = () => {}
}) => {
	return (
		<div className={`input-container ${containerClassName || ""}`}>
			{label && (
				<label className={`label ${labelClassName || ""}`}>{label}</label>
			)}
			<div
				className={`input-field-container ${touched && error ? "error" : ""} ${
					inputFieldContainerClassName || ""
				}`}>
				{trailingIcon && trailingIcon}
				<input
					autoComplete={autoComplete ? autoComplete.toString() : autoComplete}
					className={`input-field ${className || ""}`}
					name={name}
					type={type}
					value={value}
					onChange={onChange}
					onError={onError}
					onBlur={onBlur}
					placeholder={placeholder}
				/>
				{leadingIcon && leadingIcon}
			</div>
			{touched && error && <label className="azTitle azTitle--no-margin azTitle_XSmall error-label">{error}</label>}
		</div>
	);
};

export default LoginInputFields;
